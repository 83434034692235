import React from "react";
import propTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import IsrButton from "../IsrButton/IsrButton";
import "./IsrPanel.scss";

const IsrPanel = ({ title, textContent, img, link }) => {
  return (
    <div className="panel-container">
      {img && link && (
        <Link to={link}>
          <div className={`panel-image ${!img ? "d-none" : ""}`}>
            <GatsbyImage
              className="panel-image"
              image={img.node.localFile?.childImageSharp?.gatsbyImageData}
              alt={img.node.alt || "featured page"}
            />
          </div>
        </Link>
      )}

      <div className="panel-content">
        {title && <h3>{title}</h3>}
        {textContent && (
          <span
            className="panel-excerpt" // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: textContent,
            }}
          />
        )}
      </div>
      <div className="panel-button">
        <Link to={link}>
          <IsrButton type="button" variant="primary" size="md">
            Read More
          </IsrButton>
        </Link>
      </div>
    </div>
  );
};

IsrPanel.propTypes = {
  title: propTypes.string.isRequired,
  textContent: propTypes.string.isRequired,
  link: propTypes.string.isRequired,
  img: propTypes.object.isRequired,
};

export default IsrPanel;
