import React from "react";
import Spacer from "../../../Spacer/Spacer";
import IsrLabel from "../../../IsrLabel/IsrLabel";
import { useHowWeDeclareCrisisStatus } from "../../../../hooks/useHowWeDeclare/useHowWeDeclareCrisisStatus";

const CrisisStatusSection = () => {
  const {
    sectionHeading,
    preparatoryCopy,
    declaredCopy,
    proposalSubmissionsNowClosedCopy,
    showSection,
  } = useHowWeDeclareCrisisStatus();

  if (showSection)
    return (
      <div className="container">
        <Spacer mobileSize={30} size={40} />
        {sectionHeading && (
          <>
            <h2>{sectionHeading}</h2>
            <Spacer mobileSize={30} size={40} />
          </>
        )}

        <div className="row">
          {preparatoryCopy && (
            <div className="col-md-6 col-lg-4">
              <div className="crisis-type">
                <IsrLabel
                  variant="hoverable"
                  color="blue"
                  size="md"
                  removeInfoIcon
                >
                  Preparatory
                </IsrLabel>
                <Spacer mobileSize={30} size={50} />
                <h3>Preparatory</h3>
                <div
                  className="content-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: preparatoryCopy,
                  }}
                />
              </div>
            </div>
          )}
          {declaredCopy && (
            <div className="col-md-6 col-lg-4">
              <div className="crisis-type">
                <IsrLabel
                  variant="hoverable"
                  color="pink"
                  size="md"
                  removeInfoIcon
                >
                  Declared
                </IsrLabel>
                <Spacer mobileSize={30} size={50} />
                <h3>Declared</h3>
                <div
                  className="content-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: declaredCopy,
                  }}
                />
              </div>
            </div>
          )}
          {proposalSubmissionsNowClosedCopy && (
            <div className="col-md-6 col-lg-4">
              <div className="crisis-type">
                <IsrLabel
                  variant="hoverable"
                  color="grey"
                  size="md"
                  removeInfoIcon
                >
                  Proposal submissions now closed
                </IsrLabel>
                <Spacer mobileSize={30} size={50} />
                <h3>Proposal submissions now closed</h3>
                <div
                  className="content-wrapper"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: proposalSubmissionsNowClosedCopy,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );

  return null;
};

export default CrisisStatusSection;
