import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HowWeDeclare from "../components/HowWeDeclare/HowWeDeclare";
import Layout from "../components/layout/layout";

const HowWeDeclareCrisis = () => {
  return (
    <Layout>
      <Header />
      <HowWeDeclare />
      <Footer />
    </Layout>
  );
};

export default HowWeDeclareCrisis;
