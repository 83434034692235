import { useStaticQuery, graphql } from "gatsby";
export const useHowWeDeclareHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: { templateName: { eq: "Page How We Decalre A Crisis" } }
      ) {
        howWeDeclareACrisis {
          howWeDeclareACrisisHeadingSection {
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.howWeDeclareACrisis.howWeDeclareACrisisHeadingSection;
};
