import { useStaticQuery, graphql } from "gatsby";
export const useHowWeDeclareTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: { templateName: { eq: "Page How We Decalre A Crisis" } }
      ) {
        content
      }
    }
  `);
  return data.wpPage.content;
};
