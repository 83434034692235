/* eslint-disable react/no-danger */
import React from "react";
import { useHowWeDeclareTopContent } from "../../../../hooks/useHowWeDeclare/useHowWeDeclareTopContent";
import Spacer from "../../../Spacer/Spacer";

const TopContent = () => {
  const content = useHowWeDeclareTopContent();
  return (
    content && (
      <div className="container">
        <Spacer mobileSize={50} size={100} />
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8 description-text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <Spacer mobileSize={30} size={40} />
            <hr size={3} className="divider grey" />
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    )
  );
};

export default TopContent;
