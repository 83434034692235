import React from "react";
import Jumbotron from "../Jumbotron/Jumbotron";
import "./HowWeDeclare.scss";
import TopContent from "./components/TopContent/TopContent";
import CrisisStatusSection from "./components/CrisisStatusSection/CrisisStatusSection";
import { useHowWeDeclareHeadingSection } from "../../hooks/useHowWeDeclare/useHowWeDeclareHeadingSection";
import { useHowWeDeclareFeaturedPages } from "../../hooks/useHowWeDeclare/useHowWeDeclareFeaturedPages";
import FeaturedPages from "../FeaturedPages/FeaturedPages";

const HowWeDeclare = () => {
  const { heading, backgroundImage } = useHowWeDeclareHeadingSection();
  const { featuredPage1, featuredPage2, showSection } =
    useHowWeDeclareFeaturedPages();
  return (
    <div className="how-we-declare">
      <Jumbotron media={backgroundImage} title={heading} />
      <TopContent />
      <CrisisStatusSection />
      <FeaturedPages
        showSection={showSection}
        featuredPage1={featuredPage1}
        featuredPage2={featuredPage2}
      />
    </div>
  );
};

export default HowWeDeclare;
