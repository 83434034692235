import { useStaticQuery, graphql } from "gatsby";

export const useHowWeDeclareCrisisStatus = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: { templateName: { eq: "Page How We Decalre A Crisis" } }
      ) {
        howWeDeclareACrisis {
          crisisStatusesSection {
            sectionHeading
            preparatoryCopy
            declaredCopy
            proposalSubmissionsNowClosedCopy
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.howWeDeclareACrisis.crisisStatusesSection;
};
