import React from "react";
import PropTypes from "prop-types";
import Spacer from "../Spacer/Spacer";
import IsrPanel from "../IsrPanel/IsrPanel";

const getExcerptFromHtml = (pageData) => {
  if (pageData.pageExcerpt && pageData.pageExcerpt.pageExcerpt)
    return pageData.pageExcerpt.pageExcerpt;
  if (pageData.content) {
    let text = pageData.content.replace(/<[^>]+>/g, "").toString();
    if (text.length > 180) text = `${text.substring(0, 180)}...`;
    return text;
  }
  return false;
};

const FeaturedPages = ({ featuredPage1, featuredPage2, showSection }) => {
  return (featuredPage1 || featuredPage2) && showSection ? (
    <div className="container">
      <Spacer mobileSize={30} size={70} />
      <div className="row">
        <div className="col-md-0 col-lg-1 col-xl-2" />
        <div className="col-md-12 col-lg-10 col-xl-8">
          <div className="row">
            {featuredPage1 && (
              <div className="col-md-6">
                <IsrPanel
                  link={featuredPage1.uri}
                  title={featuredPage1.title}
                  textContent={getExcerptFromHtml(featuredPage1)}
                  img={featuredPage1.featuredImage}
                />
              </div>
            )}
            {featuredPage2 && (
              <div className="col-md-6">
                <IsrPanel
                  link={featuredPage2.uri}
                  title={featuredPage2.title}
                  textContent={getExcerptFromHtml(featuredPage2)}
                  img={featuredPage2.featuredImage}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-0 col-lg-1 col-xl-2" />
      </div>
      <Spacer mobileSize={30} size={70} />
    </div>
  ) : null;
};
FeaturedPages.defaultProps = {
  showSection: false,
  featuredPage1: {
    uri: "/",
    title: null,
    featuredImage: null,
  },
  featuredPage2: {
    uri: "/",
    title: null,
    featuredImage: null,
  },
};

FeaturedPages.propTypes = {
  showSection: PropTypes.bool,
  featuredPage1: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
    featuredImage: PropTypes.object,
  }),
  featuredPage2: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
    featuredImage: PropTypes.object,
  }),
};

export default FeaturedPages;
